import React from 'react';
import s from './styles.module.scss'
import {useDimensions} from "../../../../hooks/useDimensions";

const HomeTwitter = () => {

  const {width} = useDimensions()

  // useEffect(() => {
  //   const resizeIframe = () => {
  //     const iframe = document.getElementById('flockler-embed-iframe-186c645669e016bae22eaeae38c0549f');
  //
  //     if (iframe) {
  //       iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
  //       console.log(iframe.contentWindow.document.body.scrollHeight)
  //     }
  //   };
  //
  //   window.addEventListener('resize', resizeIframe);
  //
  //   return () => {
  //     window.removeEventListener('resize', resizeIframe);
  //   };
  // }, []);


  return (
    <div className={s.homeTwitter}>
      <iframe
        title='twitter'
        src="https://plugins.flockler.com/embed/iframe/1872347952e030a0bf3e17ac61f18736/18723f906e400d37d81401fb0c986b4b"
        id="flockler-embed-iframe-186c645669e016bae22eaeae38c0549f"
        height={width > 1024 ? 1100 : 2600}
        allowFullScreen>
      </iframe>
      <a target='_blank' rel={'noreferrer'} href='https://twitter.com/REBEL_BOTS' className={s.share}>
        <span>
          View more on Twitter
        </span>
      </a>
    </div>
  );
}

export default HomeTwitter;